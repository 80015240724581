<template>
  <div class="banner-item">
    <div class="swiper-list" :class="{swleft}">
      <div class="swiper-item img-cover"
        v-for="(item, index) in swiperList"
        :key="index"
        :style="{
          'z-index': 99 - index,
          'transform': `translate(${index * 10}px, ${index * 10}px)`
        }"
        @click="bannerJump(item)"
      >
        <img :src="item.Img" />
      </div>
    </div>
    <div class="dot-list-item">
      <div class="dot" :class="bannerIndex == index ? 'active' : ''" v-for="(item, index) in list" :key="index"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PcHomeBanner',
  props: {
    list: Array
  },
  data() {
    return {
      bannerIndex: 0,
      swiperList: [],
      swleft: false,
      swright: false,
      timer: null
    }
  },
  watch: {
    list(val) {
      if (val && val.length > 0) {
        this.swiperList = [
          ...val.slice(-1),
          ...val,
          ...val.slice(0, 1)
        ]
        this.setSwiperAnimation()
      }
    }
  },
  methods: {
    setSwiperAnimation() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.timer = setInterval(() => {
        this.changeSwiper(1)
      }, 4000);
    },
    debounce(delay) {
      if (this.clear) {
        return false
      }
      this.clear = setTimeout(() => {
        this.clear = null
      }, delay)
      return true
    },
    changeSwiper(type) {
      // 防抖
      if (!this.debounce(360)) {
        return
      }
      if (type == 1) { //下一个
        this.swleft = true
        const { bannerIndex, list } = this
        this.bannerIndex = bannerIndex + 1 >= list.length ? 0 : bannerIndex + 1
        setTimeout(() => {
          this.swleft = false
          this.swiperList.push(this.swiperList[2])
          this.swiperList.splice(0, 1)
        }, 350);
      } else { //上一个
        this.swright = true
        setTimeout(() => {
          this.swright = false
          this.swiperList.unshift(this.swiperList[this.swiperList.length - 3])
          this.swiperList.pop()
        }, 350);
      }
    },
    bannerJump(item) {
      this.$emit('bannerJump', item)
    }
  }
}
</script>

<style scoped lang="less">
.banner-item {
  width: 900px;
  height: 200px;
  position: relative;
  margin-top: -10px;
  .swiper-list {
    display: flex;
    &.swleft {
      .swiper-item {
        &:nth-child(2) {
        animation: fadel 0.4s ease-in-out 1; /* 淡入淡出动画持续1秒，使用ease-in-out速度曲线，播放1次 */
        }
        &:nth-child(3) {
          animation: fadel2 0.4s ease-in-out 1;
        }
        &:nth-child(4) {
          animation: fadel3 0.4s ease-in-out 1;
        }
        &:nth-child(5) {
          animation: fadel4 0.4s ease-in-out 1;
        }
      }
    }
    .swiper-item {
      width: 900px;
      height: 200px;
      border-radius: 20px;
      overflow: hidden;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      position: relative;
      &:last-child,
      &:first-child {
        opacity: 0;
      }
    }
    .swiper-item+.swiper-item {
      margin-left: -900px;
    }
  }
  .dot-list-item {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    .dot {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, .6);
      margin: 0 4px;
      transition: all .4s;
      &.active {
        width: 32px;
        background-color: #fff;
      }
    }
  }
}
@keyframes fadel {
  0% { opacity: 1;left: 0;top: 0; }
  100% { opacity: 0;left: -10px;top: -10px; }
}
@keyframes fadel2 {
  0% { left: 0;top: 0; }
  100% { left: -10px;top: -10px;transform: translate(10, 10); }
}
@keyframes fadel3 {
  0% { left: 0;top: 0; }
  100% { left: -10px;top: -10px;transform: translate(20, 20); }
}
@keyframes fadel4 {
  0% { left: 0;top: 0; }
  100% { left: -10px;top: -10px; opacity: 1;transform: translate(30, 30); }
}
</style>
